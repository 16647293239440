import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import { Form, Checkbox } from "antd"

import { Localization } from "@src/localization"
import CampaignNavigation from "@src/components/campaign/navigation"
import ActionBar from "@src/components/campaign/actionBar"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"
import CustomContainer from "@src/components/customContainer"
import {
  GET_TEMPLATE,
  Template,
  UPDATE_TEMPLATE,
  CreateTemplateInput,
} from "@src/queries/template"
import Loading from "@src/components/loading"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import PostcardPreview from "@src/components/postcard"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import Bold from "@src/components/ui/bold"
import PostcardPreviewContext from "@src/components/postcard/previewContext"
import GalleryModal from "@src/components/gallery"
import { UploadType } from "@src/queries/gallery"
import FileUpload, { FileFormat } from "@src/components/fileUpload"
import {
  BaseTemplates,
  postcards4x6,
} from "@src/sections/createCampaign/content/templates"

interface SelectPhotoProps {
  campaignId?: number
  templateId?: number
}

const SelectPhoto = ({
  campaignId,
  templateId,
}: SelectPhotoProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // page content
  const photoContent = React.useContext(Localization).selectPhoto
  const fileContent = React.useContext(Localization).fileUpload

  // form
  const [form] = Form.useForm()

  // template for preview
  const {
    isFront,
    template: previewTemplate,
    setTemplate: setPreviewTemplate,
  } = React.useContext(PostcardPreviewContext)

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: { templateId },
    skip: !templateId,
  })

  // update template
  const [updateTemplateMutation, updateTemplateResp] = useMutation(
    UPDATE_TEMPLATE
  )

  const draggerRef = React.useRef<HTMLDivElement | null>(null)

  // is gallery open
  const [isGalleryOpen, setIsGalleryOpen] = React.useState<boolean>(false)

  // template data
  const template =
    getTemplateResp &&
    getTemplateResp.data &&
    (getTemplateResp.data.template as Template)

  React.useEffect(() => {
    if (template) {
      setPreviewTemplate && setPreviewTemplate(template)
    }
  }, [getTemplateResp])

  // on form submit
  const onSubmit = async () => {
    try {
      const values = await form.validateFields()
      console.log("values", values)

      // update template
      await updateTemplateMutation({
        variables: {
          templateId,
          body: {
            ...template,
            ...previewTemplate,
            ...values,
          },
        },
      })
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  // show loader
  if (getTemplateResp.loading || !previewTemplate)
    return <Loading withOverlay={true} />

  const setImageFile = (fileUrl: string) => {
    const toUpdate: Partial<CreateTemplateInput> = {}
    if (isFront) {
      toUpdate.frontPhotoPublicUrl = fileUrl
    } else {
      toUpdate.backPhotoPublicUrl = fileUrl
    }

    setPreviewTemplate &&
      setPreviewTemplate({
        ...(previewTemplate as Template),
        ...toUpdate,
      })
  }

  // selected template
  const selectedTemplate =
    previewTemplate &&
    postcards4x6.find(
      p => p.template.baseTemplateId === previewTemplate.baseTemplateId
    )

  return (
    <div css={[tw`flex bg-white flex-col`]}>
      {/* show loading during update */}
      {updateTemplateResp.loading && <Loading withOverlay={true} />}

      {isGalleryOpen && (
        <GalleryModal
          uploadType={UploadType.Background}
          onCancel={() => setIsGalleryOpen(false)}
          onSuccess={(fileUrl: string) => {
            setImageFile(fileUrl)
            setIsGalleryOpen(false)
          }}
        />
      )}

      {/* form */}
      <Form
        name="selectPhoto"
        form={form}
        initialValues={{
          frontPhotoScaleToFit: template.frontPhotoScaleToFit,
          backPhotoScaleToFit: template.backPhotoScaleToFit,
        }}
        onValuesChange={changedValues => {
          setPreviewTemplate &&
            setPreviewTemplate({
              ...(previewTemplate as Template),
              ...changedValues,
            })
        }}
      >
        <CustomContainer customCss={tw`flex flex-col`}>
          {/* campaign navigation */}
          <CampaignNavigation campaignId={campaignId} templateId={templateId} />

          {/* campaign orange info bar */}
          <InfoBarWithContext />

          {/* postcard editing */}
          <div tw="flex mt-4 items-end">
            {/* left container */}
            <LeftContainer>
              {previewTemplate && <PostcardPreview />}
            </LeftContainer>

            {/* right container */}
            <RightContainer
              heading={photoContent.heading}
              description={photoContent.description}
            >
              <div tw="flex flex-col items-center pt-4 pb-2">
                <CustomButton
                  color={ButtonColors.gray}
                  onClick={() => setIsGalleryOpen(true)}
                >
                  {photoContent.chooseLibrary}
                </CustomButton>
                <div tw="flex flex-col items-center mt-4">
                  <div
                    ref={draggerRef}
                    css={[
                      tw`w-9/12`,
                      css`
                        & .ant-upload-drag {
                          background-color: #fff !important;
                        }
                      `,
                    ]}
                  >
                    {/* file upload ui */}
                    <FileUpload
                      uploadType={UploadType.Background}
                      setLogoFile={setImageFile}
                      filesAllowed={[FileFormat.jpg]}
                    />

                    <div tw="flex items-center justify-between mt-2">
                      {isFront ? (
                        <Form.Item
                          name="frontPhotoScaleToFit"
                          noStyle
                          valuePropName="checked"
                        >
                          <Checkbox
                            css={css`
                              & .ant-checkbox-checked .ant-checkbox-inner {
                                background-color: #000 !important;
                              }
                            `}
                          >
                            <span tw="text-2xs text-black">
                              {photoContent.scaleToFit}
                            </span>
                          </Checkbox>
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="backPhotoScaleToFit"
                          noStyle
                          valuePropName="checked"
                        >
                          <Checkbox
                            css={css`
                              & .ant-checkbox-checked .ant-checkbox-inner {
                                background-color: #000 !important;
                              }
                            `}
                          >
                            <span tw="text-2xs text-black">
                              {photoContent.scaleToFit}
                            </span>
                          </Checkbox>
                        </Form.Item>
                      )}

                      <CustomButton
                        color={ButtonColors.gray}
                        customCss={tw`rounded-none! px-4! py-1!`}
                        onClick={() => {
                          if (draggerRef && draggerRef.current) {
                            const el = draggerRef.current.querySelector(
                              ".ant-upload.ant-upload-btn"
                            ) as HTMLElement
                            if (el) {
                              el.click()
                            }
                          }
                        }}
                      >
                        {fileContent.browse}
                      </CustomButton>
                    </div>
                  </div>
                </div>
                <div tw="flex flex-col items-center text-2xs justify-around text-black mt-4">
                  <div tw="flex">
                    <Bold>{fileContent.maxFileSizeLabel}</Bold>:{" "}
                    {fileContent.maxFileSizeValue}
                  </div>
                  <div tw="flex">
                    <Bold>{fileContent.acceptableFormats}</Bold>: .JPG Only
                  </div>
                </div>
              </div>
            </RightContainer>
          </div>
        </CustomContainer>

        {/* action bar */}
        <ActionBar
          withBoundaries={true}
          rightContainerCss={tw`w-4/12!`}
          backBtnText={
            previewTemplate.baseTemplateId === BaseTemplates.SemiCustom1
              ? ""
              : "BACK"
          }
          backBtnOnClick={() =>
            campaignId &&
            templateId &&
            selectedTemplate &&
            navigate(
              createUrl(
                navigationRoutes[
                  selectedTemplate.routes[PageOptions.PHOTO].prev as PageOptions
                ],
                {
                  campaignId,
                  templateId,
                }
              )
            )
          }
          saveBtnOnClick={async () => {
            await onSubmit()
          }}
          nextBtnOnClick={async () => {
            await onSubmit()

            campaignId &&
              templateId &&
              selectedTemplate &&
              navigate(
                createUrl(
                  navigationRoutes[
                    selectedTemplate.routes[PageOptions.PHOTO]
                      .next as PageOptions
                  ],
                  {
                    campaignId,
                    templateId,
                  }
                )
              )
          }}
        />
      </Form>
    </div>
  )
}

export default SelectPhoto
