import gql from "graphql-tag"

export interface Gallery {
  listLocKey: string[]
  listAcctKey: string[]
  galleryCoverKey: string
  id: number
  filename: string
  publicFileUrl: string
  uploadType: UploadType
  createdAt: Date
}

export interface GalleryUploadResponse {
  fileUrl: string
}

export enum UploadType {
  "Logo" = 1,
  "Background" = 2,
  "General" = 3,
}

export interface UploadFileInput {
  LocKeys: number[]
  AcctKeys: number[]
  File: File
  UploadType: UploadType
}

export const UPLOAD_IN_GALLERY = gql`
  mutation uploadInGallery($body: UploadFileInput!) {
    image(body: $body)
      @rest(
        type: "UploadInGallery"
        path: "/v1/galleries"
        method: "POST"
        bodyKey: "body"
        bodySerializer: "formData"
      ) {
      fileUrl
    }
  }
`

const GALLERY_FRAGMENT = gql`
  fragment Gallery on Gallery {
    id
    listLocKey
    listAcctKey
    filename
    publicFileUrl
    uploadType
    createdAt
    galleryCoverKey
  }
`

export const GET_GALLERY = gql`
  query getGallery($uploadType: UploadType) {
    images(uploadType: $uploadType)
      @rest(
        type: "Gallery"
        path: "/v1/galleries?UploadType={args.uploadType}&PageSize=1000"
      ) {
      ...Gallery
    }
  }
  ${GALLERY_FRAGMENT}
`
