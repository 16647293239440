import React from "react"
import tw, { css } from "twin.macro"
import { Form } from "antd"

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import PostcardPreviewContext from "./previewContext"
import { cmyk2hex, string2cmyk } from "@src/utils/color"
import { Template } from "@src/queries/template"
import { BaseTemplates } from "@src/sections/createCampaign/content/templates"
import RecallTemplate1 from "./recallTemplate1"
import RecallTemplate2 from "./recallTemplate2"
import RecallTemplate3 from "./recallTemplate3"
import CustomTemplate1 from "./customTemplate1"
import AcquisitionTemplate1 from "./acquisitionTemplate1"

const PostcardPreview = ({
  editable = false,
}: {
  editable?: boolean
}): React.ReactElement | null => {
  // postcard preview context
  const { template, isFront, setIsFront, setTemplate } = React.useContext(
    PostcardPreviewContext
  )

  // form
  const [form] = Form.useForm()

  // clear field
  const clearField = (fieldName: string) => {
    updateFormFields({
      [fieldName]: "",
    })
  }

  const updatePreviewTemplate = () =>
    setTemplate &&
    setTemplate({
      ...(template as Template),
      ...form.getFieldsValue(),
    })

  const updateFormFields = (updateFields: any) => {
    form.setFieldsValue(updateFields)
    updatePreviewTemplate()
  }

  if (!template) {
    return null
  }

  return (
    <Form
      name="postcard"
      form={form}
      initialValues={{
        ...template,
      }}
    >
      <div tw="flex flex-col items-end">
        <div
          css={[
            tw`border`,
            css`
              width: 480px;
              height: 320px;
              font-size: 14px;
              color: ${cmyk2hex(string2cmyk(template.colorCode1))};
            `,
          ]}
        >
          {template.baseTemplateId === BaseTemplates.RecallTemplate1 && (
            <RecallTemplate1
              updateFormFields={updateFormFields}
              clearField={clearField}
              editable={editable}
            />
          )}
          {template.baseTemplateId === BaseTemplates.RecallTemplate2 && (
            <RecallTemplate2
              updateFormFields={updateFormFields}
              clearField={clearField}
              editable={editable}
            />
          )}
          {template.baseTemplateId === BaseTemplates.RecallTemplate3 && (
            <RecallTemplate3
              updateFormFields={updateFormFields}
              clearField={clearField}
              editable={editable}
            />
          )}
          {template.baseTemplateId === BaseTemplates.SemiCustom1 && (
            <CustomTemplate1 />
          )}
          {template.baseTemplateId === BaseTemplates.AcquisitionTemplate1 && (
            <AcquisitionTemplate1
              updateFormFields={updateFormFields}
              clearField={clearField}
              editable={editable}
            />
          )}
        </div>
        {setIsFront && (
          <div tw="flex mt-2">
            {isFront ? (
              <>
                <CustomButton
                  color={ButtonColors.black}
                  customCss={tw`rounded-r-none!`}
                >
                  Front Side
                </CustomButton>
                <CustomButton
                  color={ButtonColors.white}
                  customCss={tw`rounded-l-none!`}
                  onClick={() => setIsFront && setIsFront(false)}
                >
                  Back Side
                </CustomButton>
              </>
            ) : (
              <>
                <CustomButton
                  color={ButtonColors.white}
                  customCss={tw`rounded-r-none!`}
                  onClick={() => setIsFront && setIsFront(true)}
                >
                  Front Side
                </CustomButton>
                <CustomButton
                  color={ButtonColors.black}
                  customCss={tw`rounded-l-none!`}
                >
                  Back Side
                </CustomButton>
              </>
            )}
          </div>
        )}
      </div>
    </Form>
  )
}

export default PostcardPreview
