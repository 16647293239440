import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import { Form, Select, Checkbox, Input, Tooltip } from "antd"
import { ChromePicker } from "react-color"
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons"

import { Localization } from "@src/localization"
import CampaignNavigation from "@src/components/campaign/navigation"
import ActionBar from "@src/components/campaign/actionBar"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"
import CustomContainer from "@src/components/customContainer"
import {
  GET_TEMPLATE,
  Template,
  UPDATE_TEMPLATE,
  TextFields,
} from "@src/queries/template"
import Loading from "@src/components/loading"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import PostcardPreview from "@src/components/postcard"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import PostcardPreviewContext from "@src/components/postcard/previewContext"
import { hex2cmyk, string2cmyk, cmyk2hex, cmyk2string } from "@src/utils/color"
import fonts, { fontWeight } from "@src/components/layout/fonts"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CopyContext from "@src/context/copyContext"
import InputLabel from "./InputLabel"
import ColorValues from "./ColorValues"
import PatientVariableModal from "@src/components/patientVariable"
import { postcards4x6 } from "@src/sections/createCampaign/content/templates"
import { unescapeMessageFields } from "@src/utils/unescapeMessageFields"

interface SelectPhotoProps {
  campaignId?: number
  templateId?: number
}

const Divider = () => <div tw="border-b border-gray-400 flex w-full"></div>

const SelectPhoto = ({
  campaignId,
  templateId,
}: SelectPhotoProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // page content
  const copyContent = React.useContext(Localization).selectCopy

  // show/hide color picker
  const [showColorPicker, setShowColorPicker] = React.useState<boolean>(false)

  // show/hide patient variables
  const [showVars, setShowVars] = React.useState<boolean>(false)

  // form
  const [form] = Form.useForm()

  // template for preview
  const {
    template: previewTemplate,
    setTemplate: setPreviewTemplate,
  } = React.useContext(PostcardPreviewContext)

  // editable field context
  const { field: selectedField } = React.useContext(CopyContext)
  const editField = selectedField ? selectedField : TextFields.message1

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: { templateId },
    skip: !templateId,
  })

  // update template
  const [updateTemplateMutation, updateTemplateResp] = useMutation(
    UPDATE_TEMPLATE
  )

  // template data
  const template =
    getTemplateResp &&
    getTemplateResp.data &&
    (getTemplateResp.data.template as Template)

  React.useEffect(() => {
    if (template) {
      setPreviewTemplate && setPreviewTemplate(template)
    }
  }, [getTemplateResp])

  // on form submit
  const onSubmit = async () => {
    try {
      const values = await form.validateFields()

      // change unescape html chars
      const templateValues: any = previewTemplate
        ? unescapeMessageFields(previewTemplate)
        : {}

      // update template
      await updateTemplateMutation({
        variables: {
          templateId,
          body: {
            ...templateValues,
            ...values,
          },
        },
      })
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  const updateFormFields = (updateFields: any) => {
    form.setFieldsValue(updateFields)
    updatePreviewTemplate()
  }

  const setFontVariant = (selectedField: string, variant: string) => {
    let output
    if (previewTemplate) {
      const existing = (previewTemplate as any)[selectedField]
      switch (variant) {
        case fontWeight.normal:
          output = variant
          break
        case fontWeight.bold:
          if (existing === fontWeight.normal) {
            output = variant
          } else if (existing === fontWeight.bold) {
            output = fontWeight.normal
          } else if (existing === fontWeight.italics) {
            output = fontWeight.boldItalics
          } else if (existing === fontWeight.boldItalics) {
            output = fontWeight.italics
          }
          break
        case fontWeight.italics:
          if (existing === fontWeight.normal) {
            output = variant
          } else if (existing === fontWeight.bold) {
            output = fontWeight.boldItalics
          } else if (existing === fontWeight.italics) {
            output = fontWeight.normal
          } else if (existing === fontWeight.boldItalics) {
            output = fontWeight.bold
          }
          break
      }
      updateFormFields({
        [selectedField]: output,
      })
    }
  }

  const updatePreviewTemplate = () =>
    setPreviewTemplate &&
    setPreviewTemplate({
      ...(previewTemplate as Template),
      ...form.getFieldsValue(),
    })

  // show loader
  if (getTemplateResp.loading || !previewTemplate)
    return <Loading withOverlay={true} />

  // selected template
  const selectedTemplate =
    previewTemplate &&
    postcards4x6.find(
      p => p.template.baseTemplateId === previewTemplate.baseTemplateId
    )

  return (
    <div css={[tw`flex bg-white flex-col`]}>
      {/* show loading during update */}
      {updateTemplateResp.loading && <Loading withOverlay={true} />}

      {/* show/hide patient vars */}
      {showVars && (
        <PatientVariableModal
          isVisible
          onCancel={() => setShowVars(false)}
          onSuccess={(vars: string[]) => {
            setPreviewTemplate &&
              setPreviewTemplate({
                ...(previewTemplate as Template),
                [selectedField as string]: [
                  (previewTemplate as any)[selectedField as string],
                  ...vars,
                ].join(" "),
              })
            setShowVars(false)
          }}
        />
      )}

      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation campaignId={campaignId} templateId={templateId} />

        {/* campaign orange info bar */}
        <InfoBarWithContext />

        {/* postcard editing */}
        <div tw="flex mt-4 items-end">
          {/* left container */}
          <LeftContainer>
            {previewTemplate && <PostcardPreview editable />}
          </LeftContainer>

          {/* right container */}
          <RightContainer
            heading={copyContent.heading}
            description={copyContent.description}
          >
            {/* form */}
            <Form
              name="selectCopy"
              form={form}
              initialValues={{
                ...template,
              }}
              onValuesChange={() => updatePreviewTemplate()}
            >
              <div tw="relative mb-8">
                <div
                  css={[
                    tw`absolute w-full h-full top-0 left-0 opacity-75 z-20 bg-white`,
                    selectedField && tw`hidden`,
                  ]}
                ></div>
                {Object.values(TextFields)
                  .filter(f => f === editField)
                  .map(field => (
                    <div
                      key={field}
                      css={[
                        tw`flex flex-col items-center`,
                        css`
                          & .ant-select-selector {
                            border-radius: 50px !important;
                          }
                          & .ant-row.ant-form-item {
                            margin-bottom: 0px !important;
                          }
                        `,
                      ]}
                    >
                      <div tw="flex w-full my-4 pt-2">
                        <div tw="flex w-8/12 flex-col justify-end">
                          <InputLabel>{copyContent.fontSize}</InputLabel>
                          <Form.Item
                            name={`${field}FontFamily`}
                            rules={[
                              {
                                required: true,
                                message: "Please select font!",
                              },
                            ]}
                            wrapperCol={{ span: 22 }}
                          >
                            <Select placeholder="Please select a font">
                              {fonts.map(font => (
                                <Select.Option
                                  key={font.value}
                                  value={font.value}
                                >
                                  {font.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>

                        <div tw="flex w-4/12 flex-col justify-end">
                          <Form.Item
                            name={`${field}FontSize`}
                            rules={[
                              {
                                required: true,
                                message: "Please select font size!",
                              },
                            ]}
                            wrapperCol={{ span: 24 }}
                          >
                            <Select placeholder="Please select font size">
                              {Array.from({ length: 18 }, (_v, i) => i + 6).map(
                                size => (
                                  <Select.Option
                                    value={size.toString()}
                                    key={size}
                                  >
                                    {size}pt
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>

                      <Divider />

                      <div tw="flex w-full my-4">
                        <div tw="flex w-2/12 flex-col justify-end">
                          <InputLabel>{copyContent.color}</InputLabel>

                          <Form.Item
                            name={`${field}Color`}
                            rules={[
                              {
                                required: true,
                                message: "Please input color!",
                              },
                            ]}
                            css={tw`hidden!`}
                          >
                            <Input />
                          </Form.Item>

                          <div
                            css={[
                              tw`w-8 h-8 rounded-full border border-gray-400 cursor-pointer relative`,
                              previewTemplate &&
                                css`
                                  background-color: ${cmyk2hex(
                                    string2cmyk(
                                      (previewTemplate as any)[`${field}Color`],
                                      true
                                    )
                                  )};
                                `,
                            ]}
                            onClick={() => setShowColorPicker(true)}
                          >
                            {showColorPicker && (
                              <div
                                css={[
                                  tw`absolute left-0 ml-8 px-4 py-2 bg-white border border-gray-400 shadow-lg rounded-lg`,
                                  css`
                                    transform: translateY(-50%);
                                    &
                                      .chrome-picker
                                      > div:nth-last-child(1)
                                      > .flexbox-fix:nth-last-child(1) {
                                      display: none !important;
                                    }

                                    & .chrome-picker {
                                      border-radius: 0px !important;
                                      box-shadow: none !important;
                                    }
                                  `,
                                ]}
                              >
                                <div tw="flex items-center mb-2">
                                  <div tw="flex flex-grow justify-center text-black">
                                    Custom Color
                                  </div>
                                  <div
                                    tw="flex p-2 pr-0"
                                    onClick={e => {
                                      e.stopPropagation()
                                      setShowColorPicker(false)
                                    }}
                                  >
                                    <CloseOutlined
                                      style={{
                                        fontSize: "12px",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                  </div>
                                </div>
                                <ChromePicker
                                  color={
                                    previewTemplate
                                      ? cmyk2hex(
                                          string2cmyk(
                                            (previewTemplate as any)[
                                              `${field}Color`
                                            ],
                                            true
                                          )
                                        )
                                      : undefined
                                  }
                                  disableAlpha
                                  onChange={color => {
                                    if (color) {
                                      updateFormFields({
                                        [`${field}Color`]: cmyk2string(
                                          hex2cmyk(color.hex),
                                          true
                                        ),
                                      })
                                    }
                                  }}
                                />
                                <div tw="flex justify-around">
                                  <ColorValues
                                    messageColor={
                                      (previewTemplate as any)[`${field}Color`]
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div tw="flex w-10/12 justify-around">
                          <ColorValues
                            messageColor={
                              (previewTemplate as any)[`${field}Color`]
                            }
                          />
                        </div>
                      </div>

                      <Divider />

                      <div tw="flex w-full mt-4">
                        <div tw="flex flex-col w-6/12">
                          <InputLabel>{copyContent.fontStyle}</InputLabel>

                          <Form.Item name={`${field}Variant`} css={tw`hidden!`}>
                            <Select>
                              {[
                                fontWeight.normal,
                                fontWeight.bold,
                                fontWeight.italics,
                                fontWeight.boldItalics,
                              ].map(variant => (
                                <Select.Option value={variant} key={variant}>
                                  {variant}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <div tw="flex mt-1">
                            <div
                              css={[
                                tw`cursor-pointer pr-4`,
                                previewTemplate &&
                                [fontWeight.normal].includes(
                                  (previewTemplate as any)[`${field}Variant`]
                                )
                                  ? tw`text-black`
                                  : tw`text-gray-500`,
                              ]}
                              onClick={() =>
                                setFontVariant(
                                  `${field}Variant`,
                                  fontWeight.normal
                                )
                              }
                            >
                              T
                            </div>

                            <div
                              css={[
                                tw`cursor-pointer pr-4 font-bold`,
                                previewTemplate &&
                                [
                                  fontWeight.bold,
                                  fontWeight.boldItalics,
                                ].includes(
                                  (previewTemplate as any)[`${field}Variant`]
                                )
                                  ? tw`text-black`
                                  : tw`text-gray-500`,
                              ]}
                              onClick={() =>
                                setFontVariant(
                                  `${field}Variant`,
                                  fontWeight.bold
                                )
                              }
                            >
                              B
                            </div>

                            <div
                              css={[
                                tw`cursor-pointer pr-4 italic`,
                                previewTemplate &&
                                [
                                  fontWeight.italics,
                                  fontWeight.boldItalics,
                                ].includes(
                                  (previewTemplate as any)[`${field}Variant`]
                                )
                                  ? tw`text-black`
                                  : tw`text-gray-500`,
                              ]}
                              onClick={() =>
                                setFontVariant(
                                  `${field}Variant`,
                                  fontWeight.italics
                                )
                              }
                            >
                              I
                            </div>

                            <Form.Item
                              name={`${field}IsUnderlined`}
                              valuePropName="checked"
                              css={tw`hidden!`}
                            >
                              <Checkbox>U</Checkbox>
                            </Form.Item>

                            <div
                              css={[
                                tw`cursor-pointer pr-4 underline`,
                                (previewTemplate as any)[`${field}IsUnderlined`]
                                  ? tw`text-black`
                                  : tw`text-gray-500`,
                              ]}
                              onClick={() => {
                                updateFormFields({
                                  [`${field}IsUnderlined`]: !form.getFieldValue(
                                    `${field}IsUnderlined`
                                  ),
                                })
                              }}
                            >
                              U
                            </div>
                          </div>
                        </div>
                        <div tw="flex flex-col w-6/12">
                          <InputLabel>
                            {copyContent.addVariables}{" "}
                            <div
                              css={[
                                tw`inline-block ml-1 text-xs`,
                                css`
                                  vertical-align: text-bottom;
                                `,
                              ]}
                            >
                              <Tooltip
                                placement="topRight"
                                title={copyContent.addVariablesInfo}
                                overlayStyle={{
                                  fontSize: "0.8rem",
                                }}
                                arrowPointAtCenter
                              >
                                <InfoCircleOutlined />
                              </Tooltip>
                            </div>
                          </InputLabel>
                          <div tw="flex mt-1">
                            <CustomButton
                              color={ButtonColors.white}
                              customCss={tw`py-1!`}
                              onClick={() => setShowVars(true)}
                            >
                              {copyContent.addVariablesBtn}
                            </CustomButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Form>
          </RightContainer>
        </div>
      </CustomContainer>

      {/* action bar */}
      <ActionBar
        withBoundaries={true}
        rightContainerCss={tw`w-4/12!`}
        backBtnOnClick={() =>
          campaignId &&
          templateId &&
          selectedTemplate &&
          navigate(
            createUrl(
              navigationRoutes[
                selectedTemplate.routes[PageOptions.COPY].prev as PageOptions
              ],
              {
                campaignId,
                templateId,
              }
            )
          )
        }
        saveBtnOnClick={async () => {
          await onSubmit()
        }}
        nextBtnOnClick={async () => {
          await onSubmit()

          campaignId &&
            templateId &&
            selectedTemplate &&
            navigate(
              createUrl(
                navigationRoutes[
                  selectedTemplate.routes[PageOptions.COPY].next as PageOptions
                ],
                {
                  campaignId,
                  templateId,
                }
              )
            )
        }}
      />
    </div>
  )
}

export default SelectPhoto
