import React from "react"
import tw, { css } from "twin.macro"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "@src/components/ui/modal"
import { Form, Radio } from "antd"

interface PatientVariable {
  label: string
  value: string
  info: string
}

const variables: PatientVariable[] = [
  {
    label: "<<PATIENT_FIRST_NAME>>",
    value: "<<PATIENT_FIRST_NAME>>",
    info: "Pass patient first name",
  },
  {
    label: "<<PATIENT_LAST_NAME>>",
    value: "<<PATIENT_LAST_NAME>>",
    info: "Pass patient last name",
  },
  {
    label: "<<PATIENT_ADDRESS_1>>",
    value: "<<PATIENT_ADDRESS_1>>",
    info: "Pass patient street address 1",
  },
  {
    label: "<<PATIENT_ADDRESS_2>>",
    value: "<<PATIENT_ADDRESS_2>>",
    info: "Pass patient street address 2",
  },
  {
    label: "<<PATIENT_CITY>>",
    value: "<<PATIENT_CITY>>",
    info: "Pass patient city",
  },
  {
    label: "<<PATIENT_STATE>>",
    value: "<<PATIENT_STATE>>",
    info: "Pass patient state",
  },
  {
    label: "<<PATIENT_ZIP>>",
    value: "<<PATIENT_ZIP>>",
    info: "Pass patient ZIP code",
  },
  {
    label: "<<PATIENT_COUNTRY>>",
    value: "<<PATIENT_COUNTRY>>",
    info: "Pass patient country",
  },
  {
    label: "<<PRACTICE_NAME>>",
    value: "<<PRACTICE_NAME>>",
    info: "Practice name",
  },
  {
    label: "<<PRACTICE_EMAIL>>",
    value: "<<PRACTICE_EMAIL>>",
    info: "Practice email address",
  },
  {
    label: "<<PRACTICE_ADDR1>>",
    value: "<<PRACTICE_ADDR1>>",
    info: "Practice street address 1",
  },
  {
    label: "<<PRACTICE_ADDR2>>",
    value: "<<PRACTICE_ADDR2>>",
    info: "Practice street address 2",
  },
  {
    label: "<<PRACTICE_CITY>>",
    value: "<<PRACTICE_CITY>>",
    info: "Practice city",
  },
  {
    label: "<<PRACTICE_STATE>>",
    value: "<<PRACTICE_STATE>>",
    info: "Practice state",
  },
  {
    label: "<<PRACTICE_ZIP>>",
    value: "<<PRACTICE_ZIP>>",
    info: "Practice Zip code",
  },
  {
    label: "<<PRACTICE_PHONE>>",
    value: "<<PRACTICE_PHONE>>",
    info: "Practice phone number",
  },
  {
    label: "<<PRACTICE_WEBSITE>>",
    value: "<<PRACTICE_WEBSITE>>",
    info: "Practice website address",
  },
  { label: "<<LOCATION_NAME>>", value: "<<LOCATION_NAME>>", info: "Location" },
  {
    label: "<<LOCATION_EMAIL>>",
    value: "<<LOCATION_EMAIL>>",
    info: "Location email address",
  },
  {
    label: "<<LOCATION_ADDR1>>",
    value: "<<LOCATION_ADDR1>>",
    info: "Location street address 1",
  },
  {
    label: "<<LOCATION_ADDR2>>",
    value: "<<LOCATION_ADDR2>>",
    info: "Location street address 2",
  },
  {
    label: "<<LOCATION_CITY>>",
    value: "<<LOCATION_CITY>>",
    info: "Location city",
  },
  {
    label: "<<LOCATION_STATE>>",
    value: "<<LOCATION_STATE>>",
    info: "Location state",
  },
  {
    label: "<<LOCATION_ZIP>>",
    value: "<<LOCATION_ZIP>>",
    info: "Location Zip code",
  },
  {
    label: "<<LOCATION_PHONE>>",
    value: "<<LOCATION_PHONE>>",
    info: "Location phone number",
  },
  {
    label: "<<PROVIDER_PREFIX>>",
    value: "<<PROVIDER_PREFIX>>",
    info: "Provider prefix",
  },
  {
    label: "<<PROVIDER_FIRST_NAME>>",
    value: "<<PROVIDER_FIRST_NAME>>",
    info: "Provider first name",
  },
  {
    label: "<<PROVIDER_LAST_NAME>>",
    value: "<<PROVIDER_LAST_NAME>>",
    info: "Provider last name",
  },
  {
    label: "<<PROVIDER_DEGREE>>",
    value: "<<PROVIDER_DEGREE>>",
    info: "Provider degree",
  },
  {
    label: "<<PROVIDER_EMAIL>>",
    value: "<<PROVIDER_EMAIL>>",
    info: "Provider email address",
  },
  {
    label: "<<PROVIDER_PHONE>>",
    value: "<<PROVIDER_PHONE>>",
    info: "Provider phone number",
  },
]

const Title = () => (
  <CustomModal.Title heading={"Patient Information"}> </CustomModal.Title>
)

const Footer = ({ onSuccess }: { onSuccess: any }) => (
  <div tw="flex py-3 px-16 justify-end">
    <CustomButton
      color={ButtonColors.blue}
      onClick={() => onSuccess()}
      customCss={tw`uppercase`}
    >
      Save
    </CustomButton>
  </div>
)

interface CustomModalProps {
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  isVisible: boolean
  onSuccess: (vars: string[]) => void
}

const PatientVariableModal = ({
  isVisible,
  onCancel,
  onSuccess,
}: CustomModalProps): React.ReactElement => {
  const [form] = Form.useForm()

  return (
    <Form
      name="patientVariables"
      form={form}
      initialValues={{
        selectedVariable: variables[0].value,
      }}
    >
      <CustomModal
        title={<Title />}
        isVisible={isVisible}
        onCancel={onCancel}
        footer={
          <Footer
            onSuccess={() => {
              onSuccess([form.getFieldValue("selectedVariable")])
            }}
          />
        }
      >
        <div tw="flex flex-col px-16 py-8">
          <p css={[tw`text-black text-xs`]}>
            Select a patient information variable from the list below to add it
            to your copy.
          </p>

          <div
            css={[
              tw`flex mt-4 overflow-auto justify-center`,
              css`
                max-height: 50vh;
                & .ant-row.ant-form-item {
                  width: 80%;
                }
                & .ant-radio-wrapper {
                  font-size: 0.75rem;
                }
                & .ant-radio-inner::after {
                  background-color: #000;
                }
              `,
            ]}
          >
            <Form.Item name="selectedVariable">
              <Radio.Group css={tw`w-full`}>
                {variables.map(oneVar => (
                  <div key={oneVar.value} tw="flex  w-full">
                    <div tw="flex w-1/2 text-xs! font-bold">
                      <Radio value={oneVar.value}>{oneVar.label}</Radio>
                    </div>
                    <div tw="flex w-1/2 text-gray-600 text-xs">
                      ({oneVar.info})
                    </div>
                  </div>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </CustomModal>
    </Form>
  )
}

export default PatientVariableModal
